<template>
  <div id="jobs-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/jobs/add" class="btn btn-primary mb-5">Job hinzufügen</router-link>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Google Analytics and Google Ads integration</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/personas">Personas</router-link></li>
      <li>Connect to <router-link to="/values">Value Propositions</router-link></li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/contents">Content Library</router-link></li>
      <li>Connect to <router-link to="/keywords">Keyword Planner</router-link></li>
      <li>Connect to <router-link to="/kpis">KPI Tools</router-link></li>
    </ul>-->

    <IntroBanner v-if="!(jobs && jobs.length)" link="/jobs/add"></IntroBanner>

    <div v-if="jobs.length" class="row">
      <div class="col-sm-4">
        <h3 class="h4">Funktionale Jobs</h3>
        <div v-for="(job, index) in jobs.filter(j => j.type === 'functional')"
             @click="selectedJobId = job.id"
             class="card clickable mb-2" :class="selectedJobId === job.id ? 'border-primary' : ''"
             :key="'f' + index"
        >
          <div class="card-body">
            <i class="fad fa-wrench fa-fw"></i> {{ job.title }}
            <!--<i @click="remove(job.id)" class="fal fa-minus-circle text-danger float-right"></i>-->
          </div>
        </div>
        <h3 class="h4 mt-3">Soziale Jobs</h3>
        <div v-for="(job, index) in jobs.filter(j => j.type === 'social')"
             @click="selectedJobId = job.id"
             class="card clickable mb-2" :class="selectedJobId === job.id ? 'border-primary' : ''"
             :key="'s' + index"
        >
          <div class="card-body">
            <i class="fad fa-users fa-fw"></i> {{ job.title }}
            <!--<i @click="remove(job.id)" class="fal fa-minus-circle text-danger float-right"></i>-->
          </div>
        </div>
        <h3 class="h4 mt-3">Emotionale Jobs</h3>
        <div v-for="(job, index) in jobs.filter(j => j.type === 'emotional')"
             @click="selectedJobId = job.id"
             class="card clickable mb-2" :class="selectedJobId === job.id ? 'border-primary' : ''"
             :key="'e' + index"
        >
          <div class="card-body">
            <i class="fad fa-heart fa-fw"></i> {{ job.title }}
            <!--<i @click="remove(job.id)" class="fal fa-minus-circle text-danger float-right"></i>-->
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div v-if="selectedJob" class="card">
          <div class="edit-button">
            <router-link :to="'/jobs/edit/' + selectedJob.id"><i class="fad fa-pencil"></i></router-link>
          </div>
          <div class="remove-button" @click="remove(selectedJob.id)">
            <i class="fas fa-times"></i>
          </div>
          <div class="card-header">
            <h2><i class="fad fa-fw" :class="selectedJob.type === 'functional' ? 'fa-wrench' : selectedJob.type ===
            'social' ? 'fa-users' : 'fa-heart'"></i> {{ selectedJob.title }}</h2>
            <p class="lead mb-0">{{ selectedJob.type }}</p>
          </div>
          <div class="card-body">
            <p>{{ selectedJob.description }}</p>

            <h3>Job-Schritte</h3>

            <div v-if="!selectedJob.jobSteps.length">
              <p class="text-muted">Keine Schritte hinzugefügt</p>
              <router-link :to="'/jobs/edit/' + selectedJob.id" class="btn btn-primary">Job bearbeiten</router-link>
            </div>
            <div v-else class="scroll-container-h">
              <div class="row flex-nowrap">
                <div v-for="(jobStep, index) in selectedJob.jobSteps" class="col-5" :key="index">
                  <div class="card h-100">
                    <div class="card-img-top">
                      <div class="job-step-chart p-3">
                        <div class="bar bar-satisfaction" :style="'height:' + jobStep.satisfaction / 5 * 100 + '%'"></div>
                        <div class="bar bar-potential" :style="'height:' + jobStep.potential / 5 * 100 + '%'"></div>
                      </div>
                    </div>
                    <div class="card-body">
                      <h4 class="h5">{{ jobStep.name }}</h4>

                      <h5 class="h6">Phase:</h5>
                      <p>{{ jobStep.phase }}</p>

                      <h5>Needs:</h5>
                      <ul>
                        <li v-for="(need, index) in jobStep.needs" :key="index">{{ need }}</li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <button @click="alert('Generate new Idea Overlay here')" class="btn btn-primary">Generate Idea</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import IntroBanner from "@/components/IntroBanner";
export default {
  name: 'Jobs',
  components: {IntroBanner},
  data() {
    return {
      selectedJobId: "",
    }
  },
  computed: {
    jobs() {
      return this.project.jobs.map(job => {
        job.personas = this.project.personas.filter(p => p.jobIds.find(jobId => jobId === job.id));
        return job;
      });
    },
    selectedJob() {
      return this.jobs.find(j => j.id === this.selectedJobId)
    },
  },
  methods: {
    remove(id) {
      let job = this.project.jobs.find(j => j.id === id);
      if (confirm('Remove "' + job.title + '" from Journeys?')) {
        let index = this.project.jobs.findIndex(p => p.id === id);
        this.project.jobs.splice(index, 1);
        this.$store.dispatch('project/updateProjectByProp', {prop: 'jobs', data: this.project.jobs})
            .then(res => {
              console.debug(res);
            });
      }
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.selectedJobId = this.$route.params.id;
    } else if (this.jobs.length) {
      this.selectedJobId = this.jobs[0].id;
    }
  }
}
</script>

<style lang="scss" scoped>
  .job-step-chart {
    position: relative;
    height: 6rem;
    margin-top: 1rem;

    .bar {
      position: absolute;
      bottom: 0;
      width: calc(50% - 1.5rem);

      &.bar-satisfaction {
        left: 1rem;
        background: var(--primary);
        opacity: .5;
      }
      &.bar-potential {
        right: 1rem;
        background: var(--danger);
        opacity: .5;
      }
    }
  }
</style>